import { template as template_07b8048c8f2c4c01ba02f44af5505004 } from "@ember/template-compiler";
const FKLabel = template_07b8048c8f2c4c01ba02f44af5505004(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
